.row{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}
.row > div {
    align-items: flex-start;
}
.pimgContainer{
    width: 130px;
}
.pimg {
    height: 130px;
    max-width: 130px;
}
.column {
    justify-content: space-between;
}
.personName {
    color: darkblue;
    font-size: large;
    font-weight: 500;
}
.modified {
    background-color: var(--burialModifiedField-Color);
}
.personInfo {
    display: flex;
    gap: 4px;
}