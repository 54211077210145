.authContainer{
    display: flex;
  }
.userName {
    color: azure;
    white-space: nowrap;
}
.headerLogo {
  float: left;
  /* width: 120px;
  height: 31px; */
  /* margin: 16px 24px 16px 0; */
  background: rgba(255, 255, 255, 0.3);
  height: 100%;
}
.ant-row-rtl .headerLogo {
  float: right;
  margin: 16px 0 16px 24px;
}
.headerLogo div {
  padding: 12px;
  white-space: nowrap;
}
.logoimg {
  /* max-width: 100%; */
  max-height: 100%;
}
.supportLink {
  color: #fff;
}