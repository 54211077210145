.newItemContainer {
    padding-bottom: 24px;
    padding-top: 14px;
}
.editContainer{
    display: flex;
    width: 100%;
}
.editForm {
    width: 100%;
}