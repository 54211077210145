.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.root{
  min-height: 100vh;
}
.header{
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu{
  width: 100%;
}

.formRow{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.formBtn{
  display: inline-block;
  width: 100%;
  align-self: start;
  flex: 0 1 auto;
  margin-top: 32px;
}
.defaultActionButton, .commonButton {
  background-color: rgb(23, 27, 23);
  color: white;
}
.commonButton {
  width: 100%;
}
.commonButton:hover, .defaultActionButton:hover{
  background-color: rgb(2, 27, 95) !important;
  color: white !important;
}
.backcontainer{
  margin-bottom: 22px;    
}