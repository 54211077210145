.row{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}
.row > div {
    align-items: flex-start;
}
.pimgContainer{
    width: 46px;
}
.pimg {
    height: 46px;
    max-width: 46px;
}
.column {
    justify-content: space-between;
}
.personName {
    color: darkblue;
    font-size: large;
    font-weight: 500;
}
.inf {
    display: flex;
    justify-content: space-between;
    justify-items: center;
}
.w {
    width: 100%;
}