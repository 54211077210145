:root {
    --infoCircle-Color: rgba(0,0,0,.45);
}
.section{
    font-size: large;
}
.colTitle {
    justify-content: center;
}
.personCard {
    border: 1px solid #f5d2d2;
    border-radius: 10px;
    background-color: aliceblue;
    margin-bottom: 16px;
    padding: 4px;
}
.attachment {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.tempFormItem {
    display: flex;
    justify-content: flex-end;
}
.toolTipInfo {
    color: var(--infoCircle-Color);
}
#burialForm .ant-select-selection-item{
    background: transparent !important;
    border: none !important;
}
.buttons {
    gap: 4px;
}