.centered {
    justify-content: center;
    display: flex;
}
.warnIco {
    color: #ec8321;
    font-size: large;
}
.ownedBurialActions{
    padding-bottom: 18px;
}
.rejectIco {
    color: #ec5021;
    font-size: large;
}