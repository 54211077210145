.formWrapper {
    margin-left: auto;
    margin-right: auto;
    width: 324px;
}
.formBtn{
    display: inline-block;
    width: 100%;
    align-self: start;
    flex: 0 1 auto;
    margin-top: 32px;
}
.formPswChg {
    min-width: 200px;
    max-width: 324px;
}