.resultRow {
    width: 100%;
}
.resultCol {
    white-space: nowrap;
}
.addForm {
    width: 100%;
    padding: 12px;
}
.container, .addContainer{
    margin-bottom: 22px;    
}
.addContainer {
    max-width: 600px;
    min-width: 300px;
    background-color: aliceblue;
}
.addHeader {
    font-size: 18px;
}
.findFilter{
    width: 100%;
}
.findFilter > div {
    width: 100%;
    max-width: 100%;
}