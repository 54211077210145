.resultRow{
    width: 100%;
}
.resultCol{
    /* padding: 8px 0; */
    /* white-space: nowrap; */
}
@media screen and (max-width: 992px) {
    .resultCol {
      flex: 100% !important; /* !important so that it overrides the inline style because by default it has higher priority */
    }
  }
.resultCemetery{
    display: flex;
    gap: 4px;
}