.editContainer{
    display: flex;
    width: 100%;
}
.priceInput{
    min-width: 100px;
}
.w100{
    width: 100%;
}
.newItemContainer {
    padding-bottom: 24px;
    padding-top: 14px;
}