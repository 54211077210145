.groupType{
    font-weight: 500;
    font-style: italic;
}
.groupTypeColored {
    background-color: aliceblue;
}
.searchForm {
    padding-bottom: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 56px;
    margin-bottom: -1px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 400;
    font-size: 14px;
    background: transparent;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
}
.searchResultRow {
    align-items: center;
}