.searchWrapper{
    display: flex;
}
.listItem {
    align-items: flex-start !important;
}
.listItemColored:hover{
    background-color: rgb(216, 215, 215);
    cursor: pointer;
}
.findButton {
   
}
.centered {
    display: flex;
    justify-content: center;
}
.filterButtons{
    text-align: right;
}
ul[class=ant-list-item-action] {
    margin-inline-start: 0;
}
.foundLabel {
    font-weight: bold;
}
.appList{
    display: flex
}
.SortField{
    padding-right: 16px;
}
.sortSelect {
    width: 400px;
    min-width: 200px;
    max-width: 500px;
}