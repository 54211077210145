.linkItemContainer {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    border-block-end: 1px solid rgba(5,5,5,.06);
    padding: 12px 0;
}
.title {
    font-size: large;
    padding: 12px 12px 0 12px;
    margin-top: 18px;
}
.center {
    flex: 1;
}
.w {
    width: 100%;
}