.loginWrapper {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.row div{
    margin: 8px;
}
.loginButton {
    width: 100%;
    background-color: rgb(23, 27, 23);
    color: white;
}
.loginButton:hover{
    background-color: rgb(2, 27, 95);
    color: white !important;
}
.loginForm{
    margin-top: 24px;
}
.loginForm > div{
    margin-bottom: 8px;
}
.registerForm {
    min-width: 300px;
}