.centeredHeader  h2, .centeredText{
    text-align: center;
}
.centeredHeader div{
    font-size: 18px;
    margin: auto;
}
.w880 {
    max-width: 880px;
}
.w500 {
    max-width: 500px;
}
.allNewsBtn
{
    font-size: 18px;
}