.row{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 18px;
}
.row > div {
    align-items: flex-start;
    flex-wrap: wrap;
}
.section{
    font-size: large;
}
.sectionp {
    margin-left: 8px;
    display: flex;
    gap: 6px;
    align-items: center;
}
.bimg {
    height: 160px;
    max-width:100%;
    max-height:100%;
}
.inline {
    display: flex;
    width: 100%;
}
.fview{
    float:right;
    /* border: 1px dotted #1a1616; */
    height: 100%;    
    width: 50%;
}
.iview{
    float:left; 
    width: 50%;
    padding-right: 22px;
}
#react-doc-viewer {
    max-height: 600px;
}
.pimgContainer{
    max-width: 200px;
    background-color: #fff;
}
.burialInfo {
    gap: 24px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: flex-start; */
}
.last {
    /* margin-left: auto; */
}
.commonInfo{
    padding-left: 0;
    flex-grow: 1;
}
.mapLink{
    padding: 0;
}
.editPerson{
    display: flex;
    justify-content: flex-end;
}
:root {
    --burialModifiedField-Color: #7cd95661;
}
.modified {
    background-color: var(--burialModifiedField-Color);
}