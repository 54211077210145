.findWrapper{
    min-width: 480px;
    max-width: 2480px;
    display: flex;
    flex-direction: column;
}
.formBtn{
    width: 140px;
}
.resultTitle, .resultPersons {
    display: flex;
    flex-direction: column;
}
.resultTitle {
    font-weight: 700;
    color: #4d6274;
}
.findDatePeriod {
    width: 100%;
}
.findFilter div[class*=ant-form-item]{
    margin-bottom: 0;
}
.findCard{
    display: flex;
    flex-direction: column;
    flex: 1;
    /* max-width: 500px; */
}
[class*=findCard] .ant-card-body{
    flex-grow: 1;
}
.findCardImg {
    width: 164px;
    max-width: 164px;
    min-width: 100px;
}
.findCardSectionTitle{
    font-weight: bold;
    color:#4c4c4c;
    font-size: 14px;
}
.findCardSectionContent{
    font-weight: 400;
}
.findCardSection{
    display: flex;
    align-items: flex-end;
    gap: 4px;
}
.findCardPerson{
    color: #2375af;
}