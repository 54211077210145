.findCard{
    display: flex;
    flex-direction: column;
    flex: 1;
    /* max-width: 500px; */
}
[class*=findCard] .ant-card-body{
    flex-grow: 1;
}
.findCardImg {
    width: 164px;
    max-width: 164px;
    min-width: 100px;
}
.findCardSectionTitle{
    font-weight: bold;
    color:#4c4c4c;
    font-size: 14px;
}
.findCardSectionContent{
    display: flex;
    flex-direction: row;
    font-weight: 400;
    gap: 4px;
}
.findCardSection{
    display: flex;
    align-items: flex-end;
    gap: 4px;
}
.findCardPerson{
    display: flex;
    color: #2375af;
    gap: 4px;
}
.person{
    font-size: 20px;
}