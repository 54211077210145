.filterAction {
    text-align: right;
}
.findContainer{
    width: 100%;
    background: #ededed;
    border: 1px dotted #c9c9c9;
    padding: 16px;
    border-radius: 4px;
}
.resultRow {
    width: 100%;
}
.resultCol {
    white-space: nowrap;
}
.findFilter div[class*='ant-form-item'] {
    margin-bottom: 0 !important;
}