.findFilter div[class*=ant-form-item]{
    margin-bottom: 0;
}
.sortSelect {
    width: 400px;
    min-width: 200px;
    max-width: 500px;
}
.cardCell {
    width: 50%;
}